<template>
  <div style="max-width: 1250px">
    <div class="site-wrapper background-static">
      <main class="main-static">
        <div v-if="data">
          <div class="row">
            <div class="col-12">
              <h1 class="h1 mb-4">Vielen Dank für deine Bestellung</h1>
            </div>
          </div>

          <v-container class="white mt-4 border bg-white p-3" style="display: flex; flex-wrap: wrap; flex: 1 1 auto" fluid rounded>
            <div class="col-12 col-md-4">
              <h4 class="text-dark" style="width: 100%">Besteller</h4>
              <p style="margin-bottom: 0px" v-if="data.sex === 'firma'">
                {{ data.sex === 'firma' && data.company ? data.company : '' }}
              </p>
              <p style="margin-bottom: 0px">{{ data.firstname }} {{ data.lastname }}</p>
              <p style="margin-bottom: 0px">{{ data.street }} {{ data.streetNumber }}</p>
              <p style="margin-bottom: 0px">{{ data.postcode }} {{ data.city }}</p>
              <p style="margin-bottom: 0px">{{ data.remark }}</p>
              <p class="mt-3">
                {{ data.email }}
                <br />
                Telefon: {{ data.phone }}
              </p>

              <div v-if="data.userPointsEarned && data.userPointsEarned > 0" style="cursor: pointer">
                <p style="margin-bottom: 0; text-align: center">
                  <span style="font-weight: 700">
                    Klasse, du hast
                    <span class="h2" style="text-align: center; display: block; margin: 0 5px">
                      {{ data.userPointsEarned ? numberWithDot(data.userPointsEarned) : 0 }}
                    </span>
                    Punkte gesammelt.
                  </span>
                  <br />
                </p>
                <span style="text-align: center; margin-top: 5px; display: inline-block; margin-bottom: 1rem"> Bei deiner nächsten Bestellung kannst du das in Bares Geld umwandeln! </span>
              </div>
            </div>

            <div class="col-12 col-md-4">
              <h4 class="text-dark" style="width: 100%">Lieferdienst</h4>
              <p style="margin-bottom: 0px">
                {{ storeData.companyName }}
              </p>
              <p style="margin-bottom: 0px">
                {{ storeData.street }}
              </p>
              <p style="margin-bottom: 0px">{{ storeData.postcode }} {{ storeData.city }}</p>
              <p class="mt-3">
                <a :href="storeData.email">{{ storeData.email }}</a>
                <br />
                <a :href="storeData.phone">Telefon: {{ storeData.phone }}</a>
              </p>
            </div>

            <div class="col-12 col-md-4">
              <h4 class="text-dark">Informationen</h4>

              <table class="w-100" style="width: 100%">
                <tbody>
                  <tr>
                    <td>Typ:</td>
                    <td class="text-right font-weight-bold" style="padding-left: 30px">
                      {{ data.orderType == '1' ? 'Lieferung' : 'Selbstabholer' }}
                    </td>
                  </tr>

                  <tr>
                    <td>Zeitpunkt:</td>
                    <td class="text-right font-weight-bold">
                      {{ data.deliveryDate ? formateDate(data.deliveryDate) : 'Sofort' }}
                    </td>
                  </tr>

                  <tr v-if="data.discountValue || data.userPointsTotal">
                    <td>Zwischensumme:</td>
                    <td class="text-right font-weight-bold">
                      {{ convertPriceToEuroFormat(data.subTotal) }}
                    </td>
                  </tr>

                  <tr v-if="data.discountValue">
                    <td>Gutschein{{ data.couponDiscountLine.type == 1 ? `(${data.couponDiscountLine.amount}%)` : '' }}:</td>
                    <td class="text-right font-weight-bold">-{{ convertPriceToEuroFormat(data.discountValue) }}</td>
                  </tr>

                  <tr v-if="data.userPointsTotal">
                    <td>Pluspunkte {{ data.userPointsUsed }}:</td>
                    <td class="text-right font-weight-bold">-{{ convertPriceToEuroFormat(data.userPointsTotal) }}</td>
                  </tr>

                  <tr v-if="data.shipmentCharge && data.shipmentCharge > 0">
                    <td>Liefergebühr:</td>
                    <td class="text-right font-weight-bold">
                      {{ convertPriceToEuroFormat(data.shipmentCharge) }}
                    </td>
                  </tr>

                  <tr>
                    <td style="padding-top: 10px">Gesamt:</td>
                    <td style="padding-top: 10px" class="text-right font-weight-bold">
                      {{ convertPriceToEuroFormat(data.total) }}
                    </td>
                  </tr>

                  <tr>
                    <td>Bezahlung:</td>
                    <td class="text-right font-weight-bold">
                      {{ data.paymentText }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-container>

          <v-container class="white mt-5 border bg-white p-3" style="display: flex; flex-wrap: wrap; flex: 1 1 auto" fluid rounded>
            <div class="col-12">
              <h4 class="text-dark mb-4">Bestellung</h4>

              <div class="p-3">
                <div class="cart-item" v-for="(item, index) in data.lines" :key="index">
                  <div class="row">
                    <div class="col-12 col-md-5">
                      <p class="text-uppercase m-0">
                        <span class="badge badge-primary mr-2">{{ item.count }}x</span>
                        <strong>
                          {{ item.article.name.toUpperCase() }}{{ item.notCount ? '*' : '' }}
                          {{ item.nonDiscountable ? (item.notCount ? '/**' : '**') : '' }}
                        </strong>
                      </p>
                      <p class="text-muted mb-1">
                        {{ item.selectedArticleVariant.variantName.toLowerCase() === 'normal' ? '' : item.selectedArticleVariant.variantName }}
                      </p>
                    </div>

                    <div class="col-12 col-md-4">
                      <span class="text-muted" v-for="(ingredient, i) in item.selectedIngredients" :key="i">
                        + {{ ingredient.count }}x {{ ingredient.name }}
                        {{ ingredient.price ? convertPriceToEuroFormat(ingredient.price) : 'GRATIS' }}
                        {{ ingredient.price ? (ingredient.free ? 'GRATIS' : ingredient.countFree ? `${ingredient.countFree}x GRATIS` : '') : '' }}
                        <br />
                      </span>
                    </div>

                    <div class="col-12 col-md-3 text-right">
                      <span class="text-large">{{ convertPriceToEuroFormat(item.totalPrice) }}</span>
                    </div>

                    <div class="col-12" v-if="data.lines.length - 1 !== index || (data.shipmentCharge && data.shipmentCharge > 0)">
                      <hr class="" />
                    </div>
                  </div>
                </div>
                <span class="mt-2 mb-0 text-muted" style="display: block" v-if="isNotCountItem || isNonDiscountableItem">
                  <br v-if="isNotCountItem" />
                  {{ isNotCountItem ? '* wird nicht beim Mindestbestellwert berücksichtigt.' : '' }}
                  <br v-if="isNonDiscountableItem" />
                  {{ isNonDiscountableItem ? '** ist nicht rabattfähig.' : '' }}
                </span>

                <div class="cart-item" v-if="data.shipmentCharge && data.shipmentCharge > 0">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <p class="text-uppercase m-0">
                        <strong>Liefergebühr</strong>
                      </p>
                    </div>

                    <div class="col-12 col-md-4 text-right">
                      <span class="text-large">
                        {{ convertPriceToEuroFormat(data.shipmentCharge) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-container>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';

export default {
  data() {
    return {
      storeId: '',
      data: null,
      basketData: {
        number: 0,
        total: '0',
      },
    };
  },
  async created() {
    if (this.$route.params.id) {
      const response = await axios({
        method: 'get',
        url: `/order/${this.$route.params.id}`,
        baseURL: this.$store.state.storeItems[0].ApiUrl || process.env.VUE_APP_SHOP_API_BASEURL,
      })
        .then((res) => res.data)
        .catch((err) => err);
      if (response) {
        const { storeId } = response;
        const storeData = await axios({
          method: 'get',
          url: `/store/data/${storeId}`,
          baseURL: this.$store.state.storeItems[0].ApiUrl || process.env.VUE_APP_SHOP_API_BASEURL,
        })
          .then((res) => res.data)
          .catch((err) => err);
        let isNotCountItem = false;
        let isNonDiscountableItem = false;
        if (response && response.lines.length > 0) {
          isNotCountItem = Boolean(response.lines.find((item) => item.notCount));
          isNonDiscountableItem = Boolean(response.lines.find((item) => item.nonDiscountable));
        }
        this.storeId = storeId;
        this.data = response;
        this.basketData = {
          number: response.lines.length,
          total: response.total,
        };
        this.storeData = storeData.storeContainer.store;
        this.isNotCountItem = isNotCountItem;
        this.isNonDiscountableItem = isNonDiscountableItem;
      } else {
        this.$router.push({ name: 'Umsatz' });
      }
    } else {
      this.$router.push({ name: 'Umsatz' });
    }
    return '';
  },
  methods: {
    numberWithDot(number) {
      return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
    },
    formateDate(date) {
      moment.locale('de');
      return moment(date).isSame(moment(), 'day') ? `Heute ${moment(date).format('HH:mm')} Uhr` : `${moment(date).format('DD.MM.YYYY HH:mm')} Uhr`;
    },
    convertPriceToEuroFormat(value = 0) {
      return value.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });
    },
  },
};
</script>
<style scoped>
.badge {
  background: #d32f2f;
  color: white;
  padding: 2px 3px;
  border-radius: 5px;
  font-size: 90%;
  font-weight: 700;
  vertical-align: middle;
}
h4 {
  width: 100%;
  font-size: 110%;
  padding-bottom: 5px;
}
</style>
